<template>
	<div class="app-container monthMoney">
		<el-form :model="searchForm" ref="searchForm" inline>
			<el-form-item label="月份" v-show="$route.name == 'RemunerationHistory'">
				<el-date-picker
					v-model="searchForm.month"
					size="small"
					type="month"
					value-format="yyyy-MM"
					placeholder="选择查询月份"
					:clearable="false"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="部门">
				<treeselect
					:props="depProps"
					:options="deps"
					:value="searchForm.orgName"
					:clearable="true"
					:accordion="true"
					:level="searchForm.orgName"
					@getValue="_getValue($event)"
					style="width: 190px"
				/>
			</el-form-item>
			<el-form-item label="姓名">
				<el-input v-model="searchForm.staffName" size="small" placeholder="请输入姓名"></el-input>
			</el-form-item>
			<el-form-item label="工号">
				<el-input v-model="searchForm.jobNumber" size="small" placeholder="请输入工号"></el-input>
			</el-form-item>
			<el-form-item label="职位">
				<el-input v-model="searchForm.officeName" size="small"></el-input>
			</el-form-item>
			<el-form-item label="发放状态">
				<el-select v-model="searchForm.grantType" placeholder="请选择" clearable size="small">
					<el-option value="0" label="未发放 "></el-option>
					<el-option value="1" label="已发放 "></el-option>
				</el-select>
			</el-form-item>
			<!--<el-form-item label-width="5px" label=" ">
        </el-form-item>!-->
			<el-form-item style="float: right; margin-right: 0">
				<el-button type="primary" @click="onSubmit" size="small" icon="el-icon-search">查询</el-button>
				<el-button
					type="primary"
					@click="sendGrant('1')"
					:disabled="this.staffIds.length == 0"
					size="small"
					icon="el-icon-tickets"
					v-if="permissionControlBtns(pageName, 'Grant')"
					>发放工资条</el-button
				>
				<el-button
					type="primary"
					@click="sendGrant('0')"
					size="small"
					icon="el-icon-document-copy"
					v-if="permissionControlBtns(pageName, 'GrantAll')"
					>发放所有工资条</el-button
				>
				<el-button
					type="primary"
					@click="dialogImportVisible = true"
					size="small"
					icon="el-icon-upload2"
					v-if="permissionControlBtns(pageName, 'Import')"
					>导入</el-button
				>
				<el-button type="primary" @click="Export" size="small" icon="el-icon-download" v-if="permissionControlBtns(pageName, 'Export')"
					>导出</el-button
				>
				<el-button
					type="primary"
					v-if="canremove"
					@click="removeAllRecord && permissionControlBtns(pageName, 'Clear')"
					size="small"
					icon="el-icon-delete"
					>清空工资记录</el-button
				>
				<!--<el-button type="primary" v-show="$route.name=='RemunerationHistory'" @click="$router.push({name:'RemunerationSetting'})">设置</el-button>!-->
			</el-form-item>
		</el-form>
		<el-table :data="data" stripe @selection-change="handleSelectionChange" v-loading="loading" header-row-class-name="tableClass">
			<el-table-column type="selection" width="50" align="center" :selectable="isDisabled"></el-table-column>
			<el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
			<el-table-column
				v-for="col in columns"
				:prop="col.id"
				align="center"
				:key="col.id"
				:min-width="
					col.id === 'actualAttendance'
						? '120px'
						: col.id == 'staffName'
						? '120px'
						: col.id == 'realDay'
						? '130px'
						: col.id == 'orgName'
						? '210px'
						: col.id == 'jobNumber'
						? '120px'
						: '100px'
				"
				:label="col.label"
			>
			</el-table-column>
			<el-table-column prop="payableValue" align="center" label="应发工资" min-width="100px"></el-table-column>
			<el-table-column prop="paidValue" align="center" label="实发工资" min-width="100px"></el-table-column>
			<el-table-column label="操作" align="center" fixed="right" width="130">
				<template slot-scope="{ row }">
					<el-button
						type="text"
						v-if="($route.name != 'RemunerationHistory' || row.grantType != '1') && permissionControlBtns(pageName, 'Edit')"
						@click="detail(row)"
						>编辑</el-button
					>
					<el-button type="text" @click="detail(row, true)" v-if="permissionControlBtns(pageName, 'Detail')">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- <el-table :data="data" stripe  @selection-change="handleSelectionChange" v-loading="loading" v-else>
        <el-table-column label="序号" type="index" align="center"></el-table-column>
        <el-table-column v-for="col in columns"
            :prop="col.id"
            align="center"
            :key="col.id"
            :label="col.label"
        >
        </el-table-column>
        <el-table-column prop="payableValue" align="center" label="应发工资"></el-table-column>
        <el-table-column prop="paidValue" align="center" label="实发工资"></el-table-column>
        <el-table-column label="操作" align="center">
            <template slot-scope="{row}">
                <el-button type="text" @click="detail(row)">详情</el-button>
            </template>
        </el-table-column>
    </el-table>!-->
		<el-pagination
			@size-change="sizeChange"
			@current-change="currentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 40, 50, 80, 100]"
			:page-size="table.pageSize"
			style="float: right; margin: 20px 0"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.totalNum"
		>
		</el-pagination>
		<ImportCom
			:dialogImportVisible.sync="dialogImportVisible"
			:importUrl="importUrl"
			:type="type"
			:params="searchForm"
			@handleSearch="currentChange(searchForm.pageNo)"
		/>
	</div>
</template>
<script>
import treeselect from '@/components/treeSelect/treeSelect';
import ImportCom from '@/components/import';
import { payrollConfigDetail, payrollMonthList, payrollGrant, historyList, grantWhether, grantEmpty } from '../../../api/remuneration';
export default {
	components: { treeselect, ImportCom },
	computed: {
		pageName() {
			return this.$route.name;
		}
	},
	data() {
		return {
			searchForm: {
				orgName: '',
				month: '',
				staffName: '',
				jobNumber: '',
				officeName: '',
				grantType: ''
			},
			dialogTableVisible: false,
			canremove: false,
			dialogImportVisible: false,
			loading: false,
			importUrl: '/v1/payroll/month/import',
			type: 'payroll',
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			columns: [
				{ label: '姓名', id: 'staffName' },
				{ label: '工号', id: 'jobNumber' },
				{ label: '部门', id: 'orgName' },
				{ label: '职位', id: 'officeNmae' },
				{ label: '实际出勤天数', id: 'actualAttendance' },
				{ label: '发放状态', id: 'grantTypeName' }
			],
			staffIds: [],
			data: [],
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			}
		};
	},
	async mounted() {
		this.getMonthString();
		grantWhether({}).then((res) => {
			if (res._responseStatusCode == 0) {
				this.canremove = !res.grantStatus;
			}
		});
		const boolean = await this.getConfig();

		if (boolean) {
			if (this.$route.name != 'RemunerationHistory') {
				this.getMonthString(true);
				this.getList();
			} else {
				this.getMonthString();
				this.getHistoryList();
			}
		}
	},
	methods: {
		isDisabled(row) {
			if ((this.$route.name == 'RemunerationHistory' && row.grantType == '1') || !row.payrollList) {
				return false;
			}
			return true;
		},
		removeAllRecord() {
			this.$confirm('即将清空所有员工工资条记录', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				grantEmpty({}).then((res) => {
					if (res.code == 0) {
						this.$message.success('清除成功');
					}
				});
			});
		},
		getHistoryList() {
			this.loading = true;
			historyList({ ...this.searchForm, pageNo: this.table.pageNo, pageSize: this.table.pageSize }).then((res) => {
				if (res.list) {
					res.list.forEach((it) => {
						it.payrollList.forEach((it1) => {
							this.columns.forEach((it2) => {
								if (`Config${it1.id}` == it2.id) {
									it[it2.id] = it1.money;
								}
							});
						});
					});
					this.data = res.list;
					this.table.totalNum = res.totalNum;
					this.loading = false;
				}
			});
		},
		getMonthString(now) {
			const stringArr = new Date().toLocaleDateString().split('/');

			let year = Number(stringArr[0]);

			let month = Number(stringArr[1]);

			if (now) {
				month--;
			} else {
				month -= 2;
			}
			if (month == 0) {
				month = '12';
				year--;
			} else if (month < 0) {
				year--;
				month = 12 + month;
			} else if (month > 0 && month < 10) {
				month = `0${month}`;
			}
			this.searchForm.month = `${year}-${month}`;
		},
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		async getConfig() {
			const objArr = await payrollConfigDetail({});

			this.columns = [
				{ label: '姓名', id: 'staffName' },
				{ label: '工号', id: 'jobNumber' },
				{ label: '部门', id: 'orgName' },
				{ label: '职位', id: 'officeNmae' },
				{ label: '实际出勤天数', id: 'actualAttendance' },
				{ label: '发放状态', id: 'grantTypeName' }
			];
			objArr.forEach((it) => {
				this.columns.push({ id: `Config${it.id}`, label: it.typeName });
			});
			return true;
		},
		Export() {
			let url = '';

			if (this.$route.name == 'RemunerationHistory') {
				url = `${location.origin}/v1/payroll/month/export?params=${encodeURIComponent(JSON.stringify(this.searchForm))}`;
			} else {
				url = `${location.origin}/v1/payroll/month/template/export?params=${encodeURIComponent(JSON.stringify(this.searchForm))}`;
			}
			window.open(url);
		},
		sendGrant(grantStatus) {
			this.$confirm(grantStatus == '0' ? '是否确定发放所有员工的工资条信息?' : '是否确定发放所勾选员工的工资条信息?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				const params = {
					staffIds: this.staffIds,
					grantStatus,
					month: this.searchForm.month,
					grantType: this.$route.name != 'RemunerationHistory' ? '1' : '2'
				};

				payrollGrant(params).then((res) => {
					if (res._responseStatusCode == 0) {
						this.currentChange(this.table.pageNo);
						this.$message.success('工资条发放成功');
					}
				});
			});
		},
		getList() {
			payrollMonthList({ ...this.searchForm, pageNo: this.table.pageNo, pageSize: this.table.pageSize }).then((res) => {
				if (res.list) {
					res.list.forEach((it) => {
						it.payrollList &&
							it.payrollList.forEach((it1) => {
								this.columns.forEach((it2) => {
									if (`Config${it1.id}` == it2.id) {
										it[it2.id] = it1.money;
									}
								});
							});
					});
					this.data = res.list;
					this.table.totalNum = res.totalNum;
					this.loading = false;
				}
			});
		},
		handleSelectionChange(val) {
			this.staffIds = [];
			val.map((it) => {
				this.staffIds.push(it.staffId);
			});
		},
		onSubmit() {
			this.currentChange(1);
		},
		sizeChange(val) {
			this.table.pageSize = val;
			if (this.$route.name != 'RemunerationHistory') {
				this.getList();
			} else {
				this.getHistoryList();
			}
		},
		currentChange(val) {
			this.table.pageNo = val;
			if (this.$route.name != 'RemunerationHistory') {
				this.getList();
			} else {
				this.getHistoryList();
			}
		},
		detail(row, isinfo) {
			const params = {
				staffId: row.staffId,
				month: row.month,
				grantType: row.grantType,
				isthisMonth: this.$route.name != 'RemunerationHistory',
				isinfo
			};

			sessionStorage.setItem('remunerationInfo', JSON.stringify(params));
			this.$router.push({
				name: 'currentInfo',
				params: {
					staffId: row.staffId,
					month: row.month,
					isinfo,
					grantType: row.grantType,
					isthisMonth: this.$route.name != 'RemunerationHistory'
				}
			});
		}
	}
};
</script>

<style lang="scss">
.monthMoney {
	label {
		font-weight: normal;
	}
}
</style>
